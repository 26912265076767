import logo from './logo.svg';
import './App.css';
import {Route, Routes} from "react-router-dom";
import Home from "./companents/home/Home";
import User from "./companents/pages/User";

function App() {
  return (
<div>

  <Routes>

    <Route path="/" element={<User/>}/>
    <Route path="home" element={<Home/>}/>
  </Routes>

</div>  );
}

export default App;
