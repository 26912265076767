import React from 'react';
import {Link} from "react-router-dom";

const User = () => {
    return (
        <section id="user">
            <div className="container">


                <div className="user">

                    <div className="blockTitle">
                        <h2 className="name">Usonov Rolan <tr/>
                            Baatyrbekovich
                        </h2>
                        <Link to="home" className="buttonNext">
                            <div className="next">
                                <svg width="15" height="26" viewBox="0 0 15 26" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M0.5991 0.601202C1.3979 -0.200401 2.69301 -0.200401 3.49181 0.601202L14.4009 11.5486C15.1997 12.3502 15.1997 13.6498 14.4009 14.4514L3.49181 25.3988C2.69301 26.2004 1.3979 26.2004 0.5991 25.3988C-0.1997 24.5972 -0.1997 23.2975 0.5991 22.4959L10.0618 13L0.5991 3.50406C-0.1997 2.70246 -0.1997 1.4028 0.5991 0.601202Z"
                                          fill="white"/>
                                </svg>
                            </div>

                        </Link>
                    </div>

                    <h4 className="titleDev">Front-End Developer</h4>
                    <p className="description">Hey Wassap! I am a frontend
                        <tr/>
                        developer from Kyrgyzstan with <tr/>
                        experience 2 years.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default User;